<template>
  <div
    class="content"
    style="display:none;"
  >
    <div class="row justify-content-center">
      <div class="col-md-10 col-xl-6">
        <h4>{{ title }}</h4>
        <form
          enctype="multipart/form-data"
          @submit.prevent="postPortfolio"
        >
          <div class="form-group">
            <label>Business Name</label>
            <input
              v-model.trim="form.business_name"
              class="form-control"
              maxlength="255"
              name="business_name"
              required
              type="text"
            >
          </div>
          <h4>Homebase Support Account</h4>
          <p>Add a Homebase support account for this portfolio. Always use "helpdesk+[portfolio name]@homebase.ai" for the account email.</p>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>First Name</label>
                <input
                  v-model.trim="form.first_name"
                  :readonly="created"
                  class="form-control"
                  maxlength="255"
                  name="first_name"
                  required
                  type="text"
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Last Name</label>
                <input
                  v-model.trim="form.last_name"
                  :readonly="created"
                  class="form-control"
                  maxlength="255"
                  name="last_name"
                  required
                  type="text"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Email Address</label>
                <p>Use "helpdesk+[portfolio name]@homebase.ai" for the account email.</p>
                <input
                  v-model.trim="form.email"
                  :readonly="created"
                  class="form-control"
                  maxlength="255"
                  name="email"
                  required
                  type="email"
                >
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Phone Number</label>
                <input
                  v-model.trim="form.phone_number"
                  :readonly="created"
                  class="form-control"
                  maxlength="255"
                  name="phone_number"
                  required
                  type="text"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="logo">Portfolio Logo - (375px x 85px | PNG file format)</label>
                <input
                  id="logo"
                  ref="file"
                  type="file"
                  class="d-block"
                  accept="png"
                  @change="upload($event.target.files)"
                >
              </div>
            </div>
            <div
              v-if="portfolio.logo_link !== null"
              class="col-12"
            >
              <img
                :src="portfolio.logo_link"
                class="mb-3"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Address 1</label>
                <input
                  v-model.trim="form.address_1"
                  class="form-control"
                  maxlength="255"
                  name="address_1"
                  required
                  type="text"
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Address 2</label>
                <input
                  v-model.trim="form.address_2"
                  class="form-control"
                  maxlength="255"
                  name="address_2"
                  type="text"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label>City</label>
                <input
                  v-model.trim="form.business_city"
                  class="form-control"
                  maxlength="255"
                  name="business_city"
                  required
                  type="text"
                >
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>State</label>
                <select
                  v-model.trim="form.business_state"
                  class="form-control"
                  name="business_state"
                  required
                >
                  <option value="AL">
                    Alabama
                  </option>
                  <option value="AK">
                    Alaska
                  </option>
                  <option value="AZ">
                    Arizona
                  </option>
                  <option value="AR">
                    Arkansas
                  </option>
                  <option value="CA">
                    California
                  </option>
                  <option value="CO">
                    Colorado
                  </option>
                  <option value="CT">
                    Connecticut
                  </option>
                  <option value="DE">
                    Delaware
                  </option>
                  <option value="DC">
                    District Of Columbia
                  </option>
                  <option value="FL">
                    Florida
                  </option>
                  <option value="GA">
                    Georgia
                  </option>
                  <option value="HI">
                    Hawaii
                  </option>
                  <option value="ID">
                    Idaho
                  </option>
                  <option value="IL">
                    Illinois
                  </option>
                  <option value="IN">
                    Indiana
                  </option>
                  <option value="IA">
                    Iowa
                  </option>
                  <option value="KS">
                    Kansas
                  </option>
                  <option value="KY">
                    Kentucky
                  </option>
                  <option value="LA">
                    Louisiana
                  </option>
                  <option value="ME">
                    Maine
                  </option>
                  <option value="MD">
                    Maryland
                  </option>
                  <option value="MA">
                    Massachusetts
                  </option>
                  <option value="MI">
                    Michigan
                  </option>
                  <option value="MN">
                    Minnesota
                  </option>
                  <option value="MS">
                    Mississippi
                  </option>
                  <option value="MO">
                    Missouri
                  </option>
                  <option value="MT">
                    Montana
                  </option>
                  <option value="NE">
                    Nebraska
                  </option>
                  <option value="NV">
                    Nevada
                  </option>
                  <option value="NH">
                    New Hampshire
                  </option>
                  <option value="NJ">
                    New Jersey
                  </option>
                  <option value="NM">
                    New Mexico
                  </option>
                  <option value="NY">
                    New York
                  </option>
                  <option value="NC">
                    North Carolina
                  </option>
                  <option value="ND">
                    North Dakota
                  </option>
                  <option value="OH">
                    Ohio
                  </option>
                  <option value="OK">
                    Oklahoma
                  </option>
                  <option value="OR">
                    Oregon
                  </option>
                  <option value="PA">
                    Pennsylvania
                  </option>
                  <option value="RI">
                    Rhode Island
                  </option>
                  <option value="SC">
                    South Carolina
                  </option>
                  <option value="SD">
                    South Dakota
                  </option>
                  <option value="TN">
                    Tennessee
                  </option>
                  <option value="TX">
                    Texas
                  </option>
                  <option value="UT">
                    Utah
                  </option>
                  <option value="VT">
                    Vermont
                  </option>
                  <option value="VA">
                    Virginia
                  </option>
                  <option value="WA">
                    Washington
                  </option>
                  <option value="WV">
                    West Virginia
                  </option>
                  <option value="WI">
                    Wisconsin
                  </option>
                  <option value="WY">
                    Wyoming
                  </option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Zip</label>
                <input
                  v-model.trim="form.postal_code"
                  class="form-control"
                  maxlength="6"
                  name="postal_code"
                  required
                  type="text"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Business Type</label>
                <select
                  v-model="form.business_type"
                  class="form-control"
                  name="business_type"
                  required
                >
                  <option
                    v-for="type in types"
                    :key="type.id"
                    :value="type.id"
                  >
                    {{ type.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>EIN</label>
                <input
                  v-model.trim="form.ein"
                  :disabled="created"
                  class="form-control"
                  maxlength="9"
                  name="ein"
                  required
                  type="text"
                >
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-4">
              <button
                type="submit"
                :disabled="disabled"
                class="btn btn-primary btn-block"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api.js';

export default {
  name: 'Create',
  data() {
    return {
      created: false,
      disabled: false,
      form: {
        business_name: '',
        first_name: 'Homebase',
        last_name: 'Support',
        email: '',
        phone_number: '',
        portfolio_logo: '',
        address_1: '',
        address_2: '',
        business_city: '',
        business_state: 'MO',
        postal_code: '',
        business_type: '',
        ein: '',
      },
      portfolio: {},
      title: 'Create New Portfolio',
      types: [],
    };
  },
  beforeMount() {
    const self = this;
    $('.loading').css('display', 'block');

    if (self.$route.name === 'editportfolio') {
      self.created = true;

      api.getPortfolio(self.$route.params.id)
          .then((response) => {
            self.portfolio = response.portfolio;
            const result = self.portfolio.super_admins.find((admin) => admin.first === 1);
            if (self.portfolio.super_admins.length === 0) {
              self.handleError('There are no super admins in this portfolio.');
            }

            if (result !== undefined) {
              const primaryAdmin = result.admin;
              self.form.first_name = primaryAdmin.first_name;
              self.form.last_name = primaryAdmin.last_name;
            } else {
              self.handleError('There is not a primary super admin in this portfolio.');
            }

            self.title = self.portfolio.business_name;
            self.form.business_name = self.portfolio.business_name;
            self.form.email = self.portfolio.email;
            self.form.phone_number = self.portfolio.phone_number;
            self.form.address_1 = self.portfolio.address_1;
            self.form.address_2 = self.portfolio.address_2;
            self.form.business_city = self.portfolio.business_city;
            self.form.business_state = self.portfolio.business_state;
            self.form.postal_code = self.portfolio.postal_code;
            self.form.business_type = self.portfolio.business_type;
          })
          .catch((error) => {
            self.handleError(error);
          });
    }

    api.getBusinessTypes()
        .then((response) => {
          self.types = response;
          self.form.business_type = 'llc';
          $('.loading').css('display', 'none');
          $('.content').css('display', 'block');
          $('.content').animateCss('fadeIn');
        })
        .catch((error) => {
          self.handleError(error);
        });
  },
  mounted() {
    if (this.$route.query.first_name) this.form.first_name = this.$route.query.first_name;
    if (this.$route.query.last_name) this.form.last_name = this.$route.query.last_name;
    if (this.$route.query.email) this.form.email = this.$route.query.email;
    if (this.$route.query.phone_number) this.form.phone_number = this.$route.query.phone_number;
  },
  methods: {
    upload(fileList) {
      this.form.portfolio_logo = fileList[0];

      if (this.created) {
        api.postPortfolioLogo(this.portfolio.id, this.form.portfolio_logo)
            .then((response) => {
              this.portfolio.logo_link = response.logo_link;
              this.handleSuccess(
                  'The portfolio logo was uploaded successfully.',
              );
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
    },
    postPortfolio() {
      const self = this;
      if (self.disabled) return;
      self.disabled = true;

      if (!self.created) {
        api.postPortfolio(self.form)
            .then((response) => {
              self.disabled = false;
              self.$router.push(
                  {name: 'portfolio', params: {id: response.portfolio.id}},
              );
            })
            .catch((error) => {
              self.disabled = false;
              self.handleError(error);
            });
      } else {
        self.form.id = self.portfolio.id;
        api.putPortfolio(self.form)
            .then((response) => {
              self.disabled = false;
              self.$router.push(
                  {name: 'portfolio', params: {id: response.portfolio.id}},
              );
            })
            .catch((error) => {
              self.disabled = false;
              self.handleError(error);
            });
      }
    },
  },
};
</script>
