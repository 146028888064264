// Bootstrap
require('./bootstrap');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');

import ClipboardJS from 'clipboard';
// Vuelidate
import Vuelidate from 'vuelidate';
import router from './routes.js';
import store from './store';
import App from './views/App.vue';
import Vue from 'vue';
new ClipboardJS('.copy');

// Vue
window.Vue = require('vue');

Vue.use(Vuelidate);

if (process.env.MIX_NODE_ENV === 'production' || process.env.MIX_NODE_ENV === 'staging') {
  // Bugsnag
  const bugsnag = require('@bugsnag/js');
  const bugsnagVue = require('@bugsnag/plugin-vue');
  const bugsnagClient = bugsnag({
    apiKey: process.env.MIX_BUGSNAG_API_KEY,
    appVersion: process.env.MIX_APP_VERSION,
    autoCaptureSessions: true,
    releaseStage: process.env.MIX_NODE_ENV,
    notifyReleaseStages: ['staging', 'production'],
    user: {
      name: store.getters.user !== null ? store.getters.user.first_name + ' ' + store.getters.user.last_name : '',
      email: store.getters.user !== null ? store.getters.user.email : '',
      id: store.getters.user !== null ? store.getters.user.id : '',
    },
    maxEvents: 10,
  });
  bugsnagClient.use(bugsnagVue, Vue);
}

// Moment
window.moment = require('moment');

// Disable Vue Developer Tools
if (process.env.MIX_NODE_ENV !== 'local') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.mixin({
  methods: {
    // Generate a random 22 character string.
    generateId() {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    showAlert(type, message) {
      const alertId = this.generateId();

      const html = '<div class="alert ' + type + ' alert-dismissible animated fadeInDown" role="alert" id="' + alertId + '"><div class="alert-body">' + message + '</div><button type="button" class="close" aria-label="Close" id="close-' + alertId + '"><span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span></button></div>';
      $('.alerts').append(html);
      $('.loading').css('display', 'none');

      $('#close-' + alertId).on('click', function(e) {
        $('#' + alertId).removeClass('fadeInDown');
        $('#' + alertId).animateCss('fadeOutUp', function() {
          $('#' + alertId).alert('close');
          $('#' + alertId).alert('dispose');
        });
      });

      setTimeout(function() {
        $('#' + alertId).removeClass('fadeInDown');
        $('#' + alertId).animateCss('fadeOutUp', function() {
          $('#' + alertId).alert('close');
          $('#' + alertId).alert('dispose');
        });
      }, 4000);
    },
    handleSuccess(message) {
      this.showAlert('alert-success', message);
    },
    handleError(error) {
      if (typeof error === 'string') {
        this.showAlert('alert-danger', error);
        return;
      }

      if (error.response === undefined) {
        bugsnagClient.notify(error);
        this.showAlert('alert-danger', 'An unexpected error occurred. Please try again later.');
        return;
      }

      error = error.response;
      if (error.status === 401 && router.currentRoute.name !== 'login' && router.currentRoute.name !== 'register' && router.currentRoute.name !== 'forgot') {
        $('.modal').modal('hide');
        router.push({name: 'logout'});
        return;
      }

      if (error.status === 403) {
        router.push({name: 'index'});
        return;
      }

      let message = '';
      try {
        const json = JSON.parse(error.data.message);
        for (const index in json) {
          if (json.hasOwnProperty(index)) {
            message += json[index] + '<br />';
          }
        }
      } catch (exception) {
        message = error.data.message;
      }

      this.showAlert('alert-danger', message);
    },
  },
});

const app = new Vue({
  components: {App},
  router,
  store,
}).$mount('#app');
