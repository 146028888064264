<template>
  <div>
    <!-- Connect Allegion Account -->
    <div v-if="allegion == null">
      <h4>Connect Allegion Account</h4>
      <form @submit.prevent="postPortfolioExternalAccount">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="username">Username</label>
              <input
                id="username"
                v-model.trim="$v.form.username.$model"
                :class="{'is-invalid':$v.form.username.$anyError}"
                class="form-control"
                type="text"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="password">Password</label>
              <input
                id="password"
                v-model.trim="$v.form.password.$model"
                :class="{'is-invalid':$v.form.password.$anyError}"
                class="form-control"
                type="password"
              >
            </div>
          </div>
          <div
            class="col-12 col-md-4"
            style="margin-top: 1.6rem;"
          >
            <button
              :disabled="disabled || $v.$invalid"
              class="btn btn-primary btn-block"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- Allegion Account Connected -->
    <div v-if="allegion != null">
      <div class="row">
        <div class="col-auto">
          <h4 class="mb-0">
            Allegion Account Connected
          </h4>
          <p style="font-size: 24px;">
            {{ allegion.external_account.username }}
          </p>
        </div>
        <div class="col-12 col-md-5 ml-auto">
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-search fa-fw" />
              </span>
            </div>
            <input
              v-model.trim="search"
              class="form-control"
              placeholder="Search"
              type="text"
            >
          </div>
        </div>
      </div>
      <!-- Change Password Section -->
      <div class="mt-3">
        <button
          v-if="!editingPassword"
          class="btn btn-primary"
          @click="togglePasswordEdit"
        >
          Change Password
        </button>
        <div
          v-if="editingPassword"
          class="mt-3"
        >
          <div class="row">
            <div class="col-12">
              <p
                v-if="passwordHasError"
                class="text-danger"
              >
                Password must be at least 10 characters long, contain at least one uppercase letter, one lowercase letter,
                one number or symbol, and must not have more than two identical characters in a row.
              </p>
            </div>
            <div class="col-12 col-md-6">
              <label>New Password</label>
              <input
                v-model="password"
                type="password"
                class="form-control"
                :class="{'is-invalid': passwordMismatch}"
              >
              <div
                v-if="passwordMismatch"
                class="text-danger mt-1"
              >
                Passwords do not match.
              </div>
            </div>
            <div class="col-12 col-md-6">
              <label>Confirm Password</label>
              <input
                v-model="confirmPassword"
                type="password"
                class="form-control"
                :class="{'is-invalid': passwordMismatch}"
              >
            </div>
          </div>
          <div class="mt-3">
            <button
              class="btn btn-primary"
              :disabled="passwordMismatch"
              @click="confirmChange"
            >
              Save
            </button>
            <button
              class="btn btn-danger ml-2"
              @click="cancelPasswordChange"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <!-- Password Change Confirmation Modal -->
      <div
        v-if="showModal"
        class="modal fade show d-block"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Confirm Password Change
              </h5>
              <button
                type="button"
                class="close"
                @click="showModal = false"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to update the password for {{ allegion.external_account.username }}?</p>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary"
                @click="updatePassword"
              >
                Yes, Update
              </button>
              <button
                class="btn btn-danger"
                @click="showModal = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Allegion Users -->
      <div class="row">
        <AllegionUser
          v-for="user in filteredList"
          :key="user.id"
          :user="user"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api.js';
import AllegionUser from './AllegionUser.vue';
import {maxLength, required} from 'vuelidate/lib/validators';

export default {
  components: {
    AllegionUser,
  },
  props: {
    portfolio: Object,
  },
  data() {
    return {
      disabled: false,
      form: {
        username: '',
        password: '',
      },
      search: '',
      allegionObject: undefined,
      editingPassword: false,
      password: '',
      confirmPassword: '',
      showModal: false,
    };
  },
  validations: {
    form: {
      username: {
        maxLength: maxLength(255),
        required,
      },
      password: {
        maxLength: maxLength(255),
        required,
      },
    },
  },
  computed: {
    allegion() {
      return this.allegionObject || undefined;
    },
    filteredList() {
      if (!this.allegion?.application?.allegion_users) return [];
      const re = new RegExp(this.search, 'i');
      return this.allegion.application.allegion_users.filter(
          (user) =>
            user.actorable.first_name.match(re) ||
              user.actorable.last_name.match(re) ||
              user.actorable.email.match(re),
      );
    },
    passwordMismatch() {
      return this.password !== this.confirmPassword &&
          this.password !== '' &&
          this.confirmPassword !== '';
    },
    passwordHasError() {
      return this.password.length > 0 && (
        this.password.length < 10 || // Less than 10 characters
          !/[A-Z]/.test(this.password) || // No uppercase letter
          !/[a-z]/.test(this.password) || // No lowercase letter
          !/[\d\W]/.test(this.password) || // No number or symbol
          /(.)\1{2}/.test(this.password) // More than 2 same characters in a row
      );
    },
  },
  beforeMount() {
    this.getAllegionUsers();
  },
  methods: {
    getAllegionUsers() {
      api.getAllegionUsers(this.$route.params.id)
          .then((response) => {
            this.allegionObject = response.allegion;
            $('.content').css('display', 'block');
            $('.content').animateCss('fadeIn');
          })
          .catch((error) => {
            this.handleError(error);
          });
    },
    postPortfolioExternalAccount() {
      if (this.disabled || this.$v.$invalid) return;
      this.disabled = true;

      api.postPortfolioAllegionAccount(
          this.portfolio,
          this.form.username,
          this.form.password,
      )
          .then((response) => this.handleSuccess('Allegion successfully configured'))
          .catch((error) => {
            this.handleError('Unable to configure Allegion at this time.');
            this.disabled = false;
          });
    },
    togglePasswordEdit() {
      this.editingPassword = true;
    },
    cancelPasswordChange() {
      this.password = '';
      this.confirmPassword = '';
      this.editingPassword = false;
    },
    confirmChange() {
      if (!this.passwordMismatch) {
        this.showModal = true;
      }
    },
    updatePassword() {
      this.showModal = false;
      api.putExternalAccount(
          this.allegion.external_account.id,
          this.allegion.external_account.username,
          this.password,
          this.allegion.application.id,
      )
          .then((response) => {
            this.handleSuccess('Password updated successfully');
            this.cancelPasswordChange();
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.errors) {
                // Validation errors (e.g., password rules not met)
                const messages = [];
                for (const field in error.response.data.errors) {
                  if (error.response.data.errors.hasOwnProperty(field)) {
                    messages.push(error.response.data.errors[field].join(', '));
                  }
                }
                this.handleError(messages.join('<br />'));
              } else if (error.response.data.message) {
                this.handleError(error.response.data.message);
              } else {
                this.handleError('An unknown error occurred.');
              }
            } else {
              this.handleError(error.message || 'An unknown error occurred.');
            }
          });
    },
  },
};
</script>
