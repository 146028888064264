<template>
  <div class="row">
    <div class="col">
      <div
        :data-target="'#unit'+unit.id"
        class="card card-link"
        data-toggle="modal"
      >
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-4 col-xl-2 font-weight-bold">
              Unit {{ unit.unit_number }}
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2">
              Floor {{ unit.floor }}
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2">
              {{ unit.bedrooms }} Bedroom<span v-if="unit.bedrooms !== 1">s</span>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2">
              {{ unit.bathrooms }} Bathroom<span
                v-if="unit.bathrooms !== 1"
              >s</span>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2">
              {{ unit.square_feet }} sq. ft.
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2">
              ${{ unit.rent / 100 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="'unit'+unit.id"
      aria-hidden="true"
      aria-labelledby="unitLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="unitLabel"
              class="modal-title"
            >
              Unit {{ unit.unit_number }}
            </h5>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw" /></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="putUnit">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Unit Number</label>
                    <input
                      v-model.trim="form.unit_number"
                      class="form-control"
                      required
                      type="text"
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Floor</label>
                    <input
                      v-model.trim="form.floor"
                      class="form-control"
                      required
                      type="text"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Bedrooms</label>
                    <input
                      v-model.trim="form.bedrooms"
                      class="form-control"
                      min="0"
                      required
                      step="1"
                      type="number"
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Bathrooms</label>
                    <input
                      v-model.trim="form.bathrooms"
                      class="form-control"
                      min="0"
                      required
                      step="1"
                      type="number"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>Size</label>
                    <div class="input-group">
                      <input
                        v-model.trim="form.square_feet"
                        class="form-control"
                        min="0"
                        required
                        step="1"
                        type="number"
                      >
                      <div class="input-group-append">
                        <span class="input-group-text">sq. ft.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Max Residents</label>
                    <input
                      v-model.trim="form.max_residents"
                      class="form-control"
                      min="0"
                      required
                      step="1"
                      type="number"
                    >
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Total Rent</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        v-model.trim="form.total_rent"
                        class="form-control"
                        disabled
                        min="0"
                        step=".01"
                        type="number"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Unit Description</label>
                <textarea
                  v-model.trim="form.description"
                  class="form-control"
                  maxlength="255"
                />
              </div>
              <div v-if="internet">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - SSID</label>
                      <input
                        v-model.trim="form.device_ssid"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - Password</label>
                      <input
                        v-model.trim="form.device_password"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Unit WiFi - SSID</label>
                      <input
                        v-model.trim="form.unit_ssid"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Unit WiFi - Password</label>
                      <input
                        v-model.trim="form.unit_password"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="internet_automation">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP Serial</label>
                      <input
                        v-model.trim="form.ap_serial"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch Serial</label>
                      <input
                        v-model.trim="form.switch_serial"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP MAC Address</label>
                      <input
                        v-model.trim="form.ap_mac_address"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch MAC Address</label>
                      <input
                        v-model.trim="form.switch_mac_address"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch VLAN</label>
                      <input
                        v-model.trim="form.switch_vlan"
                        :class="!$v.form.switch_vlan.alphaNumUnderscore ? 'is-invalid' : ''"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP VLAN</label>
                      <input
                        v-model.trim="form.ap_vlan"
                        :class="!$v.form.ap_vlan.alphaNumUnderscore ? 'is-invalid' : ''"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch Identifier</label>
                      <input
                        v-model.trim="form.switch_identifier"
                        :class="!$v.form.switch_identifier.alphaNumUnderscore ? 'is-invalid' : ''"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP Identifier</label>
                      <input
                        v-model.trim="form.ap_identifier"
                        :class="!$v.form.ap_identifier.alphaNumUnderscore ? 'is-invalid' : ''"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="tlj_building_enabled">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Room ID</label>
                      <input
                        v-model.trim="form.tlj_room_name"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Area ID</label>
                      <input
                        v-model.trim="form.tlj_room_area_id"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Floor ID</label>
                      <input
                        v-model.trim="form.tlj_room_floor_id"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="d-none"
                type="submit"
              />
            </form>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              type="button"
            >
              Close
            </button>
            <button
              class="btn btn-danger"
              data-dismiss="modal"
              type="button"
              @click="deleteUnit"
            >
              Delete
            </button>
            <button
              :disabled="disabled || $v.$invalid"
              class="btn btn-primary"
              type="button"
              @click="putUnit"
            >
              Save
              changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api.js';

export default {
  name: 'Unit',
  props: {
    unit: Object,
    tlj_rooms: Array,
    internet: Boolean,
    internet_automation: Boolean,
    tlj_building_enabled: Boolean,
  },
  data() {
    return {
      tlj_room_id: null,
      disabled: false,
      form: {
        unit_number: '',
        floor: '',
        bedrooms: 0,
        bathrooms: 0,
        square_feet: 0,
        max_residents: 1,
        total_rent: 0.00,
        description: '',
        device_ssid: null,
        device_password: null,
        unit_ssid: null,
        unit_password: null,
        ap_serial: null,
        switch_serial: null,
        ap_mac_address: null,
        switch_mac_address: null,
        switch_vlan: null,
        ap_vlan: null,
        ap_identifier: null,
        switch_identifier: null,
        tlj_room_name: null,
        tlj_room_area_id: null,
        tlj_room_floor_id: null,
      },
    };
  },
  validations: {
    form: {
      switch_vlan: {
        alphaNumUnderscore() {
          return /^[a-zA-Z0-9_]*$/.test(this.form.switch_vlan);
        },
      },
      ap_identifier: {
        alphaNumUnderscore() {
          return /^[a-zA-Z0-9_]*$/.test(this.form.ap_identifier);
        },
      },
      switch_identifier: {
        alphaNumUnderscore() {
          return /^[a-zA-Z0-9_]*$/.test(this.form.switch_identifier);
        },
      },
      ap_vlan: {
        alphaNumUnderscore() {
          return /^[a-zA-Z0-9_]*$/.test(this.form.ap_vlan);
        },
      },
    },
  },
  computed: {
    active() {
      return moment.utc(this.unit.updated_at).fromNow();
    },
    added() {
      return moment.utc(this.unit.created_at).fromNow();
    },
    getRouteParams() {
      const portfolioId = this.$route.params.id;
      const buildingId = this.$route.params.building_id;

      if (!portfolioId || !buildingId) {
        return null;
      }
      return {
        id: portfolioId,
        building_id: buildingId,
      };
    },
  },
  beforeMount() {
    const self = this;

    self.form = self.unit;
    self.form.total_rent = self.unit.rent / 100;

    if (self.unit.ssids.length > 0) {
      self.unit.ssids.forEach(function(ssid) {
        if (ssid.tier_type.name === 'resident') {
          self.form.unit_ssid = ssid.name;
          self.form.unit_password = null;
          if (ssid.has_password) {
            self.form.unit_password = '*******';
          }
        } else if (ssid.tier_type.name === 'device') {
          self.form.device_ssid = ssid.name;
          self.form.device_password = null;
          if (ssid.has_password) {
            self.form.device_password = '*******';
          }
        }
      });
    }

    if (self.unit.unit_aruba_device_serial !== null) {
      self.form.ap_serial = self.unit.unit_aruba_device_serial.ap_serial;
      self.form.switch_serial = self.unit.unit_aruba_device_serial.switch_serial;
      self.form.ap_mac_address = self.unit.unit_aruba_device_serial.ap_mac_address;
      self.form.switch_mac_address = self.unit.unit_aruba_device_serial.switch_mac_address;
      self.form.switch_vlan = self.unit.unit_aruba_device_serial.switch_vlan;
      self.form.ap_vlan = self.unit.unit_aruba_device_serial.ap_vlan;
      self.form.ap_identifier = self.unit.unit_aruba_device_serial.ap_identifier;
      self.form.switch_identifier = self.unit.unit_aruba_device_serial.switch_identifier;
    }
    const tljRoom = self.tlj_rooms.filter((room) => room.hub_id === self.unit.hub_id);
    self.form.tlj_room_name = tljRoom.length > 0 ? tljRoom[0].tlj_room_name : null;
    self.form.tlj_room_area_id = tljRoom.length > 0 ? tljRoom[0].tlj_area_id : null;
    self.form.tlj_room_floor_id = tljRoom.length > 0 ? tljRoom[0].tlj_floor_id : null;
  },
  methods: {
    putUnit() {
      const self = this;
      if (self.disabled || self.$v.$invalid) return;
      self.disabled = true;

      if (self.form.device_password === '' ||
          self.form.device_password === '*******') {
        self.form.device_password = null;
      }
      if (self.form.unit_password === '' ||
          self.form.unit_password === '*******') {
        self.form.unit_password = null;
      }

      if (self.tlj_building_enabled && self.form.tlj_room_name) {
        api.putTljUnit(
            self.unit.id,
            self.form.tlj_room_name,
            self.form.tlj_room_area_id,
            self.form.tlj_room_floor_id,
        ).catch((error) => {
          self.disabled = false;
          self.handleError(error);
        });
      }

      api.putUnit(self.form)
          .then((response) => {
            self.disabled = false;

            $('#unit' + self.unit.id).modal('hide');

            self.form.unit_password = null;
            self.form.device_password = null;

            self.$emit('updated');
          })
          .catch((error) => {
            self.disabled = false;
            self.handleError(error);
          });
    },
    deleteUnit: function() {
      const self = this;
      if (self.disabled) return;
      self.disabled = true;
      const message = 'Would you like to delete this unit?';
      if (confirm(message)) {
        api.deleteUnit(self.form)
            .then(() => {
              $('#unit' + self.unit.id).modal('hide');
              self.handleSuccess('Unit ' + self.unit.unit_number + ' has been successfully removed');
              self.$emit('updated');

              const routeParams = self.getRouteParams;

              if (routeParams) {
                this.$router.push({
                  name: 'buildingunits',
                  params: routeParams,
                });
              }
            })
            .catch((error) => {
              self.disabled = false;
              self.handleError(error);
            });
      }
    },
  },
};
</script>
