<template>
  <div class="bg-light p-4">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h1 class="h2 mb-0">Buildings Report</h1>
        <button class="btn btn-primary" @click="handleExportButtonClicked">Export</button>
      </div>
      <!-- Feature Counts Section -->
      <div class="mb-4">
        <h2 class="h3 mb-3">Feature Counts</h2>
        <div class="row">
          <!-- Pie Chart -->
          <div class="col-lg-6 mb-4">
            <div class="card">
              <div class="card-body">
                <canvas ref="featureChart"></canvas>
                <div ref="totalCounts" class="mt-3 text-center font-weight-bold"></div>
              </div>
            </div>
          </div>
          <!-- Feature Selection -->
          <div class="col-lg-6 mb-4">
            <div class="card">
              <div class="card-body">
                <h3 class="h4 mb-3">Select Features</h3>
                <div class="mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" ref="checkAll" id="checkAll" @change="handleCheckAll" class="form-check-input">
                    <label for="checkAll" class="form-check-label">Check All</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" ref="uncheckAll" id="uncheckAll" @change="handleUncheckAll" class="form-check-input">
                    <label for="uncheckAll" class="form-check-label">Uncheck All</label>
                  </div>
                </div>
                <div class="row">
                  <div v-for="(flag, index) in Object.keys(flagCounts)" :key="index" class="col-6">
                    <div class="form-check">
                      <input type="checkbox" :id="flag" name="feature" :value="flag" v-model="selectedFeatures" @change="updateChart" class="form-check-input">
                      <label :for="flag" class="form-check-label">{{ flag.replace('has', '') }}</label>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="form-check">
                    <input type="checkbox" id="showUnits" v-model="showUnits" @change="updateChart" class="form-check-input">
                    <span class="form-check-label">Show Unit Counts</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-header" id="tableFiltersHeading">
          <h2 class="mb-0">
            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#tableFilters" aria-expanded="true" aria-controls="tableFilters">
              Table Filters
            </button>
          </h2>
        </div>
        <div id="tableFilters" class="collapse " aria-labelledby="tableFiltersHeading">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h3 class="h4 mb-3">Has any of the following features</h3>
                <div class="row">
                  <div v-for="(flag, index) in Object.keys(flagCounts)" :key="index" class="col-6">
                    <div class="form-check">
                      <input type="checkbox" :id="`${flag}_include`" name="feature_table_include" :value="flag" v-model="includeFeatures" @change="updateTable" class="form-check-input">
                      <label :for="`${flag}_include`" class="form-check-label">{{ flag.replace('has', '') }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h3 class="h4 mb-3">Has none of the following features</h3>
                <div class="row">
                  <div v-for="(flag, index) in Object.keys(flagCounts)" :key="index" class="col-6">
                    <div class="form-check">
                      <input type="checkbox" :id="`${flag}_exclude`" name="feature_table_exclude" :value="flag" v-model="excludeFeatures" @change="updateTable" class="form-check-input">
                      <label :for="`${flag}_exclude`" class="form-check-label">{{ flagCounts[flag].field }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Buildings Table -->
      <div class="table-responsive">
        <table class="table table-striped">
          <thead class="thead-light">
            <tr>
              <th>Name</th>
              <th>Portfolio</th>
              <th>Units</th>
              <th v-for="(flag, index) in Object.keys(flagCounts)" :key="index">
                {{ flagCounts[flag].field }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(building, index) in buildings" :key="index" :data-building-id="building.building_id" v-if="building.visible">
              <td class="text-nowrap">{{ building['Building Name'] }}</td>
              <td class="text-nowrap">{{ building['Portfolio Name'] }}</td>
              <td class="text-nowrap">{{ building['Unit Count'] }}</td>
              <td v-for="(flag, flagIndex) in Object.keys(flagCounts)" :key="flagIndex">
                {{ isFeatureEnabled(building, flag) ? '✅' : '❌' }}
              </td>
            </tr>
          </tbody>
          <tfoot class="thead-light">
            <tr>
              <td colspan="3" class="font-weight-bold">Total:</td>
              <td ref="totalUnits" :colspan="Object.keys(flagCounts).length + 3"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api.js';
import Papa from 'papaparse';
import Chart from 'chart.js/auto';
import FobsModal from '../components/modals/FobsModal.vue';

export default {
  components: {
    FobsModal,
  },
  data() {
    return {
      disabled: false,
      report_data: [],
      buildings: [],
      flagCounts: {
        hasXE360: { buildings: 0, units: 0, field: 'XE360', checked: true },
        hasControl: { buildings: 0, units: 0, field: 'Control', checked: true },
        hasMT20W: { buildings: 0, units: 0, field: 'MT20W', checked: true },
        hasControlb: { buildings: 0, units: 0, field: 'Control-B', checked: true },
        hasCTE: { buildings: 0, units: 0, field: 'CTE', checked: true },
        hasRCK: { buildings: 0, units: 0, field: 'RCK', checked: true },
        hasNDE: { buildings: 0, units: 0, field: 'NDE', checked: true },
        hasLE: { buildings: 0, units: 0, field: 'LE', checked: true },
        hasLEB: { buildings: 0, units: 0, field: 'LE-B', checked: true },
        hasNDEB: { buildings: 0, units: 0, field: 'NDE-B', checked: true },
        hasPayments: { buildings: 0, units: 0, field: 'Payments ' },
        hasWifi: { buildings: 0, units: 0, field: 'Internet ' },
        hasWalmartInHome: { buildings: 0, units: 0, field: 'Walmart ' },
        hasEagleEyeCameras: { buildings: 0, units: 0, field: 'Eagle Eye ' },
        hasButterfly: { buildings: 0, units: 0, field: 'Butterflymx ' },
        hasAppFolio: { buildings: 0, units: 0, field: 'Appfolio ' },
        hasEntrata: { buildings: 0, units: 0, field: 'Entrata ' },
        hasRealPage: { buildings: 0, units: 0, field: 'Realpage ' },
        hasYardi: { buildings: 0, units: 0, field: 'Yardi ' },
        hasVisitorAccess: { buildings: 0, units: 0, field: 'Shared Access ' },
        hasResidentVisitorAccess: { buildings: 0, units: 0, field: 'Visitor Access Residents' },
        hasLevitonLights: { buildings: 0, units: 0, field: 'Light Switches' },
        hasTLJLocks: { buildings: 0, units: 0, field: 'Tlj Lock' },
        hasAutomatedCommunityThermostats: { buildings: 0, units: 0, field: 'Thermostat Automation Community ' },
        hasAutomatedVacantThermostats: { buildings: 0, units: 0, field: 'Thermostat Automation Vacant ' },
        hasHoneywellThermostats: { buildings: 0, units: 0, field: 'Thermostats' },
        hasRefrigerators: { buildings: 0, units: 0, field: 'Refrigerators' },
        hasWasher: { buildings: 0, units: 0, field: 'Washer' },
        hasDryer: { buildings: 0, units: 0, field: 'Dryer' },
        hasDishwasher: { buildings: 0, units: 0, field: 'Dishwasher' },
        hasBrivo: { buildings: 0, units: 0, field: 'Brivo' },
        hasTLJ: { buildings: 0, units: 0, field: 'TLJ' },
        hasLeviton: { buildings: 0, units: 0, field: 'Leviton ' },
        hasInternetAutomation: { buildings: 0, units: 0, field: 'Internet Automation ' },
      },
      selectedFeatures: [],
      showUnits: false,
      includeFeatures: [],
      excludeFeatures: [],
      chart: null,
    };
  },
  mounted() {
    this.getReportData();
  },
  methods: {
    getReportData() {
      const self = this;
      api.getExportHomebaseCustomers()
        .then((response) => {
          response.data.text().then((data) => {
            self.report_data = Papa.parse(data, { header: true, dynamicTyping: true });
            self.processData();
            self.updateChart();
            self.updateTable();
          });
        })
        .catch((error) => {
          self.handleError(error);
        });
    },
    processData() {
      this.buildings = this.report_data.data;
      this.buildings.forEach((building, index) => {
        Object.keys(this.flagCounts).forEach((flag) => {
          if (this.isFeatureEnabled(building, flag)) {
            this.flagCounts[flag].buildings++;
            this.flagCounts[flag].units += building['Unit Count'];
          }
        });
        building.building_id = index;
      });
      this.selectedFeatures = Object.keys(this.flagCounts).filter(flag => this.flagCounts[flag].checked);
      this.includeFeatures = Object.keys(this.flagCounts);
    },
    isFeatureEnabled(building, flag) {
      if (this.flagCounts[flag].field) {
        return building[this.flagCounts[flag].field] > 0 || building[this.flagCounts[flag].field] === 'Enabled';
      }
      return !!building[flag];
    },
    updateChart() {
      const ctx = this.$refs.featureChart.getContext('2d');
      const data = this.selectedFeatures.map(feature => 
        this.showUnits ? this.flagCounts[feature].units : this.flagCounts[feature].buildings
      );
      const labels = this.selectedFeatures.map(feature => feature.replace('has', ''));

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: [
              '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
              '#FF9F40', '#33CC99', '#FF6B6B', '#7FDBFF', '#B10DC9',
              '#01FF70', '#FFDC00', '#39CCCC', '#3D9970', '#85144b',
              '#F012BE', '#0074D9', '#FF851B', '#2ECC40', '#AAAAAA'
            ]
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
            },
            title: {
              display: true,
              text: this.showUnits ? 'Feature Distribution (Units)' : 'Feature Distribution (Buildings)'
            }
          }
        }
      });

      // Calculate and display total counts
      const totalCounts = this.buildings.reduce((acc, building) => {
        if (this.selectedFeatures.some(feature => 
         this.isFeatureEnabled(building, feature) 
        )) {
          acc.buildings++;
          acc.units += building['Unit Count'];
        }
        return acc;
      }, { buildings: 0, units: 0 });

      this.$refs.totalCounts.textContent = `Buildings: ${totalCounts.buildings}, Units: ${totalCounts.units}`;
    },
    updateTable() {
      let visibleBuildings = 0;
      let visibleUnits = 0;

      this.buildings.forEach(building => {
        console.log('building', building)
        let includeBuilding = this.includeFeatures.some(feature => this.isFeatureEnabled(building, feature));
        let excludeBuilding = this.excludeFeatures.some(feature => this.isFeatureEnabled(building, feature));
        
        if (includeBuilding && !excludeBuilding) {
          building.visible = true;
          visibleBuildings++;
          visibleUnits += building['Unit Count'];
        } else {
          building.visible = false;
        }
      });

      // Update the total count in the footer
      this.$refs.totalUnits.textContent = `${visibleBuildings} buildings / ${visibleUnits} units`;
    },
    handleExportButtonClicked() {
      const self = this;
      api.getExportHomebaseCustomers()
        .then((response) => {
          // Generate CSV File of current units
          const newFile = response.data;
          const blob = new Blob([newFile], );
          const csvUrl = window.URL.createObjectURL(blob);
          //Create an a tag with the file attached
          const fileLink = document.createElement('a');
          fileLink.href = csvUrl;
          fileLink.setAttribute('download', self.getFileName(response.headers));
          // File is downloaded on click
          fileLink.click();
        })
        .catch((error) => {
          self.handleError(error);
        });
    },
    getFileName(responseHeaders) {
      if (!responseHeaders['content-disposition']) {
        return 'Homebase Customers.csv';
      }

      return responseHeaders['content-disposition'].split('"')[1];
    },
    handleCheckAll(event) {
      this.selectedFeatures = event.target.checked ? Object.keys(this.flagCounts) : [];
      this.$refs.uncheckAll.checked = false;
      this.updateChart();
    },
    handleUncheckAll(event) {
      this.selectedFeatures = event.target.checked ? [] : Object.keys(this.flagCounts);
      this.$refs.checkAll.checked = false;
      this.updateChart();
    },
    handleError(error) {
      console.error('An error occurred:', error);
      // Add error handling logic here
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>